<script>
import appConfig from "@/app.config";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      ProductAds: [],
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchProductAds: null,
      searchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    responseAlert(text, title, icon) {
      this.$swal.fire({
        title: `${title}`,
        text: ` ${text} `,
        icon: `${icon}`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    addProductAds() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.add",
        inputs: [
          { model: "name", type: "text", label: "product_ads.name_en" },
          { model: "name_ar", type: "text", label: "product_ads.name_ar" },
          { model: "price", type: "text", label: "product_ads.price" },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "success",
            handler: (obj) => {
              obj.product_id = parseInt(this.$route.params.id);
              this.http.post("product-ads", obj).then((res) => {
                if (res.status) {
                  if (this.$i18n.locale == "ar") {
                    this.responseAlert(
                      "تمت إضافة الإضافة بنجاح",
                      " تم ",
                      "success"
                    );
                  } else {
                    this.responseAlert(
                      "Ad Added successfully",
                      "done",
                      "success"
                    );
                  }
                } else {
                  if (this.$i18n.locale == "ar") {
                    this.responseAlert(
                      "حدث خطأ اثناء إضافة الإضافة",
                      "خطأ",
                      "warning"
                    );
                  } else {
                    this.responseAlert(
                      "something went wrong",
                      "error",
                      "warning"
                    );
                  }
                }
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editProductAds(app) {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "name",
            type: "text",
            label: "product_ads.name_en",
            value: app.name,
          },
          {
            model: "name_ar",
            type: "text",
            label: "product_ads.name_ar",
            value: app.name_ar,
          },
          {
            model: "price",
            type: "text",
            label: "product_ads.price",
            value: app.price,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "success",
            handler: (obj) => {
              obj.product_id = parseFloat(this.$route.params.id);
              this.http.put("product-ads", app.id, obj).then((res) => {
                if (res.status) {
                  if (this.$i18n.locale == "ar") {
                    this.responseAlert(
                      "تم تعديل الإضافة بنجاح",
                      " تم ",
                      "success"
                    );
                  } else {
                    this.responseAlert(
                      "Ad has been edited successfully",
                      "done",
                      "success"
                    );
                  }
                } else {
                  if (this.$i18n.locale == "ar") {
                    this.responseAlert(
                      "حدث خطأ اثناء تعديل الإضافة",
                      "خطأ",
                      "warning"
                    );
                  } else {
                    this.responseAlert(
                      "something went wrong",
                      "error",
                      "warning"
                    );
                  }
                }
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    search() {
      if (this.searchModel == "") {
        this.cancelappsearchMode();
        return;
      }
      this.searchMode = true;
      this.tot_pages = 0;
      this.http
        .post("product-ads/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          product_id: parseInt(this.$route.params.id),
          col: "name",
        })
        .then((res) => {
          if (res.status) {
            this.ProductAds = res.data;
          }
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.searchMode = false;
      this.get(this.page);
    },
    deleteProductAds(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("product-ads", app.id).then((res) => {
            if (res.status) {
              if (this.$i18n.locale == "ar") {
                this.responseAlert("تم حذف الإضافة بنجاح", " تم ", "success");
              } else {
                this.responseAlert(
                  "Ad has been deleted successfully",
                  "done",
                  "success"
                );
              }
            } else {
              if (this.$i18n.locale == "ar") {
                this.responseAlert(
                  "حدث خطأ اثناء حذف الإضافة",
                  "خطأ",
                  "warning"
                );
              } else {
                this.responseAlert("something went wrong", "error", "warning");
              }
            }
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("product-ads/paginate", {
          limit: this.limit,
          page: page,
          product_id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.ProductAds = res.data;
        });
    },
  },
  created() {
    this.get(1);
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input
                    @keyup="search()"
                    type="text"
                    class="form-control"
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    :placeholder="$t('popups.search')"
                    v-model="searchModel"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div
              class="col-sm-8"
              style="display: flex; justify-content: flex-end"
            >
              <button
                class="btn btn-success btn-rounded mb-2 me-2"
                @click="addProductAds()"
              >
                <i class="mdi mdi-plus me-1"></i>
                {{ $t("products.adds.title") }}
              </button>
            </div>
          </div>

          <div class="table-responsive">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("product_ads.name") }}</th>
                  <th scope="col">{{ $t("product_ads.price") }}</th>
                  <th scope="col">{{ $t("product_ads.created") }}</th>
                  <th scope="col">{{ $t("product_ads.updated") }}</th>
                  <th scope="col">{{ $t("product_ads.operations") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(app, index) in ProductAds" :key="app" class="">
                  <td>{{ index + 1 }}</td>
                  <td>{{ $i18n.locale == "ar" ? app.name_ar : app.name }}</td>
                  <td>{{ parseFloat(app?.price).toLocaleString() }}</td>
                  <td>{{ app.created.split("T")[0] }}</td>
                  <td>{{ app.updated.split("T")[0] }}</td>

                  <td>
                    <button
                      style="margin-inline-end: 8px"
                      class="btn btn-success"
                      @click="editProductAds(app)"
                    >
                      {{ $t("popups.edit") }}
                    </button>
                    <button
                      class="btn btn-danger"
                      @click="deleteProductAds(app)"
                    >
                      {{ $t("popups.delete") }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <!--   Apps  paginate     -->
            <ul class="pagination pagination-rounded justify-content-end mb-2">
              <li class="page-item" :class="{ disabled: page == 1 }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page - 1)"
                  aria-label="Previous"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li
                class="page-item"
                :class="{ active: p == page }"
                v-for="p in tot_pages"
                :key="p"
              >
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(p)"
                  >{{ p }}</a
                >
              </li>

              <li class="page-item" :class="{ disabled: page == tot_pages }">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  @click="get(page + 1)"
                  aria-label="Next"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
