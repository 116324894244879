<script>
import appConfig from "@/app.config";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      sizes: [],
      product: {},
      paginatedProduct: {},
      options: [],
      selectedProduct: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    responseAlert(text, title, icon) {
      this.$swal.fire({
        title: `${title}`,
        text: ` ${text} `,
        icon: `${icon}`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    addProductSize() {
      this.product.product_id = parseInt(this.$route.params.id);
      console.log(this.product);
      this.http.post("product-options", this.product).then((res) => {
        if (res.status) {
          this.product = {};
          if (this.$i18n.locale == "ar") {
            this.responseAlert("تمت إضافة الخيار بنجاح", " تم ", "success");
          } else {
            this.responseAlert("Option Added successfully", "done", "success");
          }
        } else {
          if (this.$i18n.locale == "ar") {
            this.responseAlert("حدث خطأ اثناء إضافة الخيار", "خطأ", "warning");
          } else {
            this.responseAlert("something went wrong", "error", "warning");
          }
        }

        this.get(this.page);
      });
    },
    search() {
      if (this.searchModel == "") {
        this.cancelappsearchMode();
        return;
      }
      this.searchMode = true;
      this.tot_pages = 0;
      this.http
        .post("product-options/search", {
          search: this.searchModel,
          limit: this.limit,
          page: this.page,
          product_id: parseInt(this.$route.params.id),
          col: "",
        })
        .then((res) => {
          this.options = res.data;
        });
    },
    cancelappsearchMode() {
      this.searchModel = "";
      this.searchMode = false;
      this.get(this.page);
    },
    processEditProductSize(app) {
      this.selectedProduct = app;
    },
    editProductSize() {
      this.http
        .put("product-options", this.selectedProduct.id, this.selectedProduct)
        .then((res) => {
          if (res.status) {
            if (res.status) {
              if (this.$i18n.locale == "ar") {
                this.responseAlert("تمت تعديل الخيار بنجاح", " تم ", "success");
              } else {
                this.responseAlert(
                  "Option hass been edited successfully",
                  "done",
                  "success"
                );
              }
            } else {
              if (this.$i18n.locale == "ar") {
                this.responseAlert(
                  "حدث خطأ اثناء تعديل الخيار",
                  "خطأ",
                  "warning"
                );
              } else {
                this.responseAlert("something went wrong", "error", "warning");
              }
            }
            this.get(this.page);
          }
        });
    },
    deleteSize(app) {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http.delete("product-options", app.id).then((res) => {
            if (res.status) {
              if (this.$i18n.locale == "ar") {
                this.responseAlert("تم حذف الخيار بنجاح", " تم ", "success");
              } else {
                this.responseAlert(
                  "Option has been delted successfully",
                  "done",
                  "success"
                );
              }
            } else {
              if (this.$i18n.locale == "ar") {
                this.responseAlert(
                  "حدث خطأ اثناء حذف الخيار",
                  "خطأ",
                  "warning"
                );
              } else {
                this.responseAlert("something went wrong", "error", "warning");
              }
            }
            console.log("############################3");
            this.get(this.page);
          });
        }
      });
    },

    get(page) {
      console.log(page);
      this.http
        .post("product-options/paginate", {
          limit: this.limit,
          page: page,
          product_id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.page = page;
          this.paginatedProduct = res.product;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.options = res.data;
          console.log(this.paginatedProduct);
        });
    },
  },
  created() {
    this.get(1);
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block">
                <div class="position-relative">
                  <input
                    :disabled="paginatedProduct?.has_options == 0"
                    @keyup="search()"
                    type="text"
                    class="form-control"
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    :placeholder="$t('popups.search')"
                    v-model="searchModel"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </div>
            <div
              class="col-sm-8"
              style="display: flex; justify-content: flex-end"
            >
              <button
                :disabled="paginatedProduct?.has_options == 0"
                class="btn btn-success btn-rounded mb-2 me-2"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
              >
                <i class="mdi mdi-plus me-1"></i>
                {{ $t("products.options.title") }}
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("products_size.name") }}</th>
                  <th scope="col">{{ $t("products_size.value") }}</th>
                  <th scope="col">{{ $t("products_size.qty") }}</th>
                  <th scope="col">{{ $t("products_size.critical_qty") }}</th>
                  <th scope="col">{{ $t("products_size.price") }}</th>
                  <th scope="col">{{ $t("products_size.cost_price") }}</th>
                  <th scope="col">{{ $t("products_size.created") }}</th>
                  <th scope="col">{{ $t("products_size.updated") }}</th>
                  <th scope="col">
                    {{ $t("products_size.operations") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(app, index) in options" :key="app" class="">
                  <td>{{ index + 1 }}</td>
                  <td>{{ app?.name }}</td>
                  <td>{{ app?.value }}</td>
                  <td>{{ app?.qty }}</td>
                  <td>{{ app?.critical_qty }}</td>
                  <td>{{ app?.price }}</td>
                  <td>{{ app?.cost_price }}</td>
                  <td>{{ app.created.split("T")[0] }}</td>
                  <td>{{ app.updated.split("T")[0] }}</td>

                  <td>
                    <button
                      style="margin-inline-end: 8px"
                      class="btn btn-success"
                      data-bs-toggle="modal"
                      data-bs-target="#editModal"
                      @click="processEditProductSize(app)"
                    >
                      {{ $t("popups.edit") }}
                    </button>
                    <button class="btn btn-danger" @click="deleteSize(app)">
                      {{ $t("popups.delete") }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <ul class="pagination pagination-rounded justify-content-end mb-2">
            <li class="page-item" :class="{ disabled: page == 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>
            <li
              class="page-item"
              :class="{ active: p == page }"
              v-for="p in tot_pages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <li class="page-item" :class="{ disabled: page == tot_pages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- end  table -->

  <!-- start add modal -->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="addProductSize()">
          <div class="modal-header d-flex justify-content-between">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.add") }}
            </h5>
            <!-- <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button> -->
          </div>

          <div class="modal-body">
            <div class="row purchase-container">
              <div class="row d-flex align-items-center mb-2">
                <div class="col">
                  <label for="asset_id">{{ $t("products_size.name") }}</label>
                  <input
                    v-model="product.name"
                    type="text"
                    class="form-control mb-2"
                    :placeholder="$t('products_size.name')"
                  />
                </div>
              </div>
              <div class="row d-flex align-items-center mb-2">
                <div class="col">
                  <label for="asset_id">{{ $t("products_size.value") }}</label>
                  <input
                    v-model="product.value"
                    type="text"
                    class="form-control mb-2"
                    :placeholder="$t('products_size.value')"
                  />
                </div>
              </div>
              <div class="row d-flex align-items-center mb-2">
                <div class="col">
                  <label for="asset_id">{{ $t("products_size.qty") }}</label>
                  <input
                    v-model="product.qty"
                    type="number"
                    class="form-control mb-2"
                    :placeholder="$t('products_size.qty')"
                  />
                </div>
              </div>
              <div class="row d-flex align-items-center mb-2">
                <div class="col">
                  <label for="asset_id">{{
                    $t("products_size.critical_qty")
                  }}</label>
                  <input
                    v-model="product.critical_qty"
                    type="number"
                    class="form-control mb-2"
                    :placeholder="$t('products_size.critical_qty')"
                  />
                </div>
              </div>
              <div class="row d-flex align-items-center">
                <div class="col">
                  <label for="asset_id">{{ $t("products_size.price") }}</label>
                  <input
                    v-model="product.price"
                    type="number"
                    class="form-control mb-2"
                    :placeholder="$t('products_size.price')"
                  />
                </div>
              </div>
              <div class="row d-flex align-items-center">
                <div class="col">
                  <label for="asset_id">{{
                    $t("products_size.cost_price")
                  }}</label>
                  <input
                    v-model="product.cost_price"
                    type="number"
                    class="form-control mb-2"
                    :placeholder="$t('products_size.cost_price')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" class="btn btn-success">
              {{ $t("popups.add") }}
            </button>
            <button
              data-bs-dismiss="modal"
              type="button"
              class="btn btn-danger"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- end add modal -->

  <!-- start edit modal -->
  <div
    class="modal fade"
    id="editModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="editProductSize()">
          <div class="modal-header d-flex justify-content-between">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("popups.edit") }}
            </h5>
            <!-- <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button> -->
          </div>

          <div class="modal-body">
            <div class="row purchase-container">
              <div class="row d-flex align-items-center mb-2">
                <div class="col">
                  <label for="asset_id">{{ $t("products_size.name") }}</label>
                  <input
                    v-model="selectedProduct.name"
                    type="text"
                    class="form-control mb-2"
                    :placeholder="$t('products_size.name')"
                  />
                </div>
              </div>
              <div class="row d-flex align-items-center mb-2">
                <div class="col">
                  <label for="asset_id">{{ $t("products_size.value") }}</label>
                  <input
                    v-model="selectedProduct.value"
                    type="text"
                    class="form-control mb-2"
                    :placeholder="$t('products_size.value')"
                  />
                </div>
              </div>
              <div class="row d-flex align-items-center mb-2">
                <div class="col">
                  <label for="asset_id">{{ $t("products_size.qty") }}</label>
                  <input
                    v-model="selectedProduct.qty"
                    type="number"
                    class="form-control mb-2"
                    :placeholder="$t('products_size.qty')"
                  />
                </div>
              </div>
              <div class="row d-flex align-items-center mb-2">
                <div class="col">
                  <label for="asset_id">{{
                    $t("products_size.critical_qty")
                  }}</label>
                  <input
                    v-model="selectedProduct.critical_qty"
                    type="number"
                    class="form-control mb-2"
                    :placeholder="$t('products_size.critical_qty')"
                  />
                </div>
              </div>
              <div class="row d-flex align-items-center">
                <div class="col">
                  <label for="asset_id">{{ $t("products_size.price") }}</label>
                  <input
                    v-model="selectedProduct.price"
                    type="number"
                    class="form-control mb-2"
                    :placeholder="$t('products_size.price')"
                  />
                </div>
              </div>
              <div class="row d-flex align-items-center">
                <div class="col">
                  <label for="asset_id">{{
                    $t("products_size.cost_price")
                  }}</label>
                  <input
                    v-model="selectedProduct.cost_price"
                    type="number"
                    class="form-control mb-2"
                    :placeholder="$t('products_size.cost_price')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" class="btn btn-success">
              {{ $t("popups.edit") }}
            </button>
            <button
              data-bs-dismiss="modal"
              type="button"
              class="btn btn-danger"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- end edit modal -->
  <!--end-->
</template>

<style scoped></style>
