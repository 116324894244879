<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import Ads from "./Ads/index.vue";
import Discount from "./Discount/index.vue";
import Orders from "./Orders/index.vue";
import Sizes from "./Sizes/index.vue";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    Ads,
    Sizes,
    Discount,
    Orders,
  },
  data() {
    return {
      product: {},
    };
  },
  methods: {
    getProduct() {
      this.http
        .get("products/" + parseInt(this.$route.params.id))
        .then((res) => {
          if (res.status) {
            this.product = res.data;
          }
        });
    },
  },
  created() {
    this.getProduct();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/products`">
            <h4 class="m-0">
              {{ $t("menu.menuitems.products.sub.products") }}
            </h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>
            {{ product?.name }}
          </div>
        </div>
      </div>
    </div>
    <b-tabs
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <!-- start departments -->
      <b-tab active>
        <template v-slot:title>
          <span :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            {{ $t("products.tabs.options") }}
          </span>
        </template>
        <Sizes />
      </b-tab>
      <!-- end departments -->

      <!-- start designations -->
      <b-tab>
        <template v-slot:title>
          <span :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            {{ $t("products.tabs.ads") }}
          </span>
        </template>
        <Ads />
      </b-tab>
      <!-- end designations -->

      <b-tab>
        <template v-slot:title>
          <span :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            {{ $t("products.tabs.discount") }}
          </span>
        </template>
        <Discount />
      </b-tab>

      <b-tab>
        <template v-slot:title>
          <span :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            {{ $t("products.tabs.orders") }}
          </span>
        </template>
        <Orders />
      </b-tab>
    </b-tabs>
  </Layout>
</template>
<style scoped></style>
