<script>
import appConfig from "@/app.config";
import store from "@/state/store";
var state = store._modules.root._children.auth.state;
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      ProductAds: [],
      product: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchProductAds: null,
      searchMode: false,
      searchModel: "",
      state: state,
    };
  },
  methods: {
    responseAlert(text, title, icon) {
      this.$swal.fire({
        title: `${title}`,
        text: ` ${text} `,
        icon: `${icon}`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    addProductAds() {
      console.log("**********####", this.state);
      var data = {
        title: "popups.add",
        inputs: [
          { model: "discount_price", type: "text", label: "product_ads.price" },
          {
            model: "discount_end",
            type: "date",
            label: "product_ads.discount_end",
          },
        ],
        buttons: [
          {
            text: "popups.add",
            closer: true,
            color: "success",
            handler: (obj) => {
              this.http
                .put("products", this.$route.params.id, obj)
                .then((res) => {
                  if (res.status) {
                    if (this.$i18n.locale == "ar") {
                      this.responseAlert(
                        "تمت إضافة التخفيض بنجاح",
                        " تم ",
                        "success"
                      );
                    } else {
                      this.responseAlert(
                        "Discount Added successfully",
                        "done",
                        "success"
                      );
                    }
                  } else {
                    if (this.$i18n.locale == "ar") {
                      this.responseAlert(
                        "حدث خطأ اثناء إضافة التخفيض",
                        "خطأ",
                        "warning"
                      );
                    } else {
                      this.responseAlert(
                        "something went wrong",
                        "error",
                        "warning"
                      );
                    }
                  }
                  this.get(this.page);
                });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    editProductAds() {
      var data = {
        title: "popups.edit",
        inputs: [
          {
            model: "discount_price",
            type: "text",
            label: "product_ads.price",
            value: this.product?.discount_price,
          },
          {
            model: "discount_end",
            type: "date",
            label: "product_ads.discount_end",
            value: this.product?.discount_end,
          },
        ],
        buttons: [
          {
            text: "popups.edit",
            closer: true,
            color: "success",
            handler: (obj) => {
              obj.product_id = parseFloat(this.$route.params.id);
              this.http.put("products", this.product.id, obj).then((res) => {
                if (res.status) {
                  if (this.$i18n.locale == "ar") {
                    this.responseAlert(
                      "تم تعديل التخفيض بنجاح",
                      " تم ",
                      "success"
                    );
                  } else {
                    this.responseAlert(
                      "Discount has been edited successfully",
                      "done",
                      "success"
                    );
                  }
                } else {
                  if (this.$i18n.locale == "ar") {
                    this.responseAlert(
                      "حدث خطأ اثناء تعديل التخفيض",
                      "خطأ",
                      "warning"
                    );
                  } else {
                    this.responseAlert(
                      "something went wrong",
                      "error",
                      "warning"
                    );
                  }
                }
                this.get(this.page);
              });
            },
          },
          {
            text: "popups.cancel",
            closer: true,
            color: "danger",
            handler: () => {
              console.log("Like Clicked");
            },
          },
        ],
      };
      this.popup.modal(data);
    },
    deleteProductAds() {
      var data = {
        title: "popups.delete",
        msg: "popups.delmessage",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          console.log("###");
          this.http
            .put("products", this.product?.id, {
              discount_price: 0,
              discount_end: null,
            })
            .then((res) => {
              if (res.status) {
                if (this.$i18n.locale == "ar") {
                  this.responseAlert("تم حذف التخفيض بنجاح", " تم ", "success");
                } else {
                  this.responseAlert(
                    "Discount has been deleted successfully",
                    "done",
                    "success"
                  );
                }
              } else {
                if (this.$i18n.locale == "ar") {
                  this.responseAlert(
                    "حدث خطأ اثناء حذف التخفيض",
                    "خطأ",
                    "warning"
                  );
                } else {
                  this.responseAlert(
                    "something went wrong",
                    "error",
                    "warning"
                  );
                }
              }
              console.log("############################3");
              this.get(this.page);
            });
        }
      });
    },

    get() {
      this.http.get("products/" + this.$route.params.id).then((res) => {
        this.product = res.data;
      });
    },
  },
  created() {
    this.get();
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-4">
              <div class="search-box me-2 mb-2 d-inline-block"></div>
            </div>
            <div
              class="col-sm-8"
              style="display: flex; justify-content: flex-end"
            >
              <button
                :disabled="product?.discount_price"
                class="btn btn-success btn-rounded mb-2 me-2"
                @click="addProductAds()"
              >
                <i class="mdi mdi-plus me-1"></i>
                {{ $t("products.adds.title") }}
              </button>
            </div>
          </div>

          <div class="table-responsive">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("product_ads.price") }}</th>
                  <th scope="col">{{ $t("product_ads.discount_end") }}</th>
                  <th scope="col">{{ $t("product_ads.operations") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="" v-if="product?.discount_price">
                  <td>{{ 1 }}</td>
                  <td>
                    {{
                      product?.discount_price
                        ? parseFloat(product?.discount_price).toLocaleString()
                        : "-"
                    }}
                  </td>
                  <td>
                    {{
                      product?.discount_end
                        ? product?.discount_end?.split("T")[0]
                        : "-"
                    }}
                  </td>

                  <td>
                    <button
                      style="margin-inline-end: 8px"
                      class="btn btn-success"
                      @click="editProductAds()"
                    >
                      {{ $t("popups.edit") }}
                    </button>
                    <button
                      class="btn btn-danger"
                      @click="deleteProductAds(app)"
                    >
                      {{ $t("popups.delete") }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <!--   Apps  paginate     -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end  table -->
  <!-- Button trigger modal -->

  <!--end-->
</template>

<style scoped>
.btn-close {
  position: relative;
  right: 317px;
  top: 6px;
  font-size: 15px;
}
</style>
