<script>
// import Transaction from "@/components/widgets/transaction";
import appConfig from "@/app.config";

/**
 * Products-order component
 */
export default {
  page: {
    title: "Orders",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Orders",
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Orders",
          active: true,
        },
      ],
      orders: [],
      sorts: [
        {
          id: 1,
          name: "رقم الطلب - تصاعديا",
          name_en: "order ID - ASC",
          key: "ID",
          type: "ASC",
        },
        {
          id: 2,
          name: "رقم الطلب - تنازليا",
          name_en: "order ID - DESC",
          key: "ID",
          type: "DESC",
        },
        {
          id: 3,
          name: "إجمالي الطلب - تصاعديا",
          name_en: "Total - ASC",
          key: "order_price",
          type: "ASC",
        },
        {
          id: 4,
          name: "إجمالي الطلب - تنازليا",
          name_en: "Total - DESC",
          key: "order_price",
          type: "DESC",
        },
        {
          id: 5,
          name: "تاريخ الطلب - تصاعديا",
          name_en: "order Date - ASC",
          key: "created",
          type: "ASC",
        },
        {
          id: 6,
          name: "تاريخ الطلب - تنازليا",
          name_en: "order Date - DESC",
          key: "created",
          type: "DESC",
        },
        {
          id: 7,
          name: "تاريخ آخر تحديث - تصاعديا",
          name_en: "order update - ASC",
          key: "updated",
          type: "ASC",
        },
        {
          id: 8,
          name: "تاريخ آخر تحديث - تنازليا",
          name_en: "order update - DESC",
          key: "updated",
          type: "DESC",
        },
      ],
      orderFilter: {},
      transactions: [
        {
          id: "#SK2540",
          name: "Neal Matthews",
          date: "07 Oct, 2019",
          total: "$400",
          status: "Paid",
          payment: ["fab fa-cc-mastercard", "Mastercard"],
          index: 1,
        },
        {
          id: "#SK2541",
          name: "Jamal Burnett",
          date: "07 Oct, 2019",
          total: "$380",
          status: "Chargeback",
          payment: ["fab fa-cc-visa", "Visa"],
          index: 2,
        },
        {
          id: "#SK2542",
          name: "Juan Mitchell",
          date: "06 Oct, 2019",
          total: "$384",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 3,
        },
        {
          id: "#SK2543",
          name: "Barry Dick",
          date: "05 Oct, 2019",
          total: "$412",
          status: "Paid",
          payment: ["fab fa-cc-mastercard", "Mastercard"],
          index: 4,
        },
        {
          id: "#SK2544",
          name: "Ronald Taylor",
          date: "04 Oct, 2019",
          total: "$404",
          status: "Refund",
          payment: ["fab fa-cc-visa", "Visa"],
          index: 5,
        },
        {
          id: "#SK2545",
          name: "Jacob Hunter",
          date: "04 Oct, 2019",
          total: "$392",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 6,
        },
        {
          id: "#SK2546",
          name: "William Cruz",
          date: "03 Oct, 2019",
          total: "$374",
          status: "Paid",
          payment: ["fas fa-money-bill-alt", "COD"],
          index: 7,
        },
        {
          id: "#SK2547",
          name: "Dustin Moser",
          date: "02 Oct, 2019",
          total: "$350",
          status: "Paid",
          payment: ["fab fa-cc-mastercard", "Mastercard"],
          index: 8,
        },
        {
          id: "#SK2548",
          name: "Clark Benson",
          date: "01 Oct, 2019",
          total: "$345",
          status: "Refund",
          payment: ["fab fa-cc-visa", "Visa"],
          index: 9,
        },
      ],
      conditions: [
        { name_en: "status", name: "الحالة", value: 0 },
        { name_en: "sorts", name: "الترتيب", value: 0 },
        { name_en: "between dates", name: "بحث بين تاريخين", value: 0 },
      ],
      ordersStatus: [],
      selectedOrder: {},
      limit: 10,
      page: 1,
      tot_pages: 0,
      searchModel: "",
      loading: false,
      accepting: false,
      rejecting: false,
    };
  },
  methods: {
    responseAlert(text, title, icon) {
      this.$swal.fire({
        title: `${title}`,
        text: ` ${text} `,
        icon: `${icon}`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    search() {
      this.loading = true;
      this.http
        .post("orders/product/search", {
          search: this.searchModel,
          id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          if (res.status) {
            this.loading = false;
            this.orders = res.data;
          }
        });
    },
    acceptOrder(order) {
      this.accepting = true;
      this.http.put("orders", order.id, { is_accepted: true }).then((res) => {
        this.accepting = false;
        if (res.status) {
          if (this.$i18n.locale == "ar") {
            this.responseAlert("تم قبول الطلب", " تم ", "success");
          } else {
            this.responseAlert("Order Accepted", "done", "success");
          }
        } else {
          if (this.$i18n.locale == "ar") {
            this.responseAlert("حدث خطأ اثناء قبول الطلب", "خطأ", "warning");
          } else {
            this.responseAlert("something went wrong", "error", "warning");
          }
        }

        order.is_accepted = true;
      });
    },
    rejectOrder(order) {
      var data = {
        title: "popups.reject",
        msg: "popups.reject_msg",
      };
      this.popup.confirm(data).then((resp) => {
        if (resp) {
          this.rejecting = true;
          console.log("###");
          this.http.delete("orders", order.id).then((res) => {
            this.rejecting = false;
            if (res.status) {
              if (this.$i18n.locale == "ar") {
                this.responseAlert("تم رفض الطلب", " تم ", "success");
              } else {
                this.responseAlert("Order rejected", "done", "success");
              }
            } else {
              if (this.$i18n.locale == "ar") {
                this.responseAlert("حدث خطأ اثناء رفض الطلب", "خطأ", "warning");
              } else {
                this.responseAlert("something went wrong", "error", "warning");
              }
            }
            this.get(this.page);
          });
        }
      });
    },
    viewOrderMsg(order) {
      this.selectedOrder = order;
    },
    getOrdersStatus() {
      this.http.get("orders-status").then((res) => {
        if (res.status) {
          this.ordersStatus = res.data;
        }
      });
    },
    filterOrders() {
      this.loading = true;
      console.log({ orderFilter: this.orderFilter });
      this.http
        .post("orders/vendor/product/search/filter", {
          conditions: this.orderFilter,
        })
        .then((res) => {
          this.loading = false;
          if (res.status) {
            this.orders = res.data;
          }
        });
      // this.orderFilter = {};
    },
    cancelFilters() {
      this.conditions.forEach((condition) => {
        condition.value = 0;
      });
      this.orderFilter = {};
      this.get(this.page);
    },
    // addFilter(){
    //   this.conditions
    // },
    get(page) {
      this.loading = true;
      this.http
        .post("orders/vendor/product/paginate", {
          limit: this.limit,
          page,
          id: parseInt(this.$route.params.id),
        })
        .then((res) => {
          this.loading = false;
          if (res.status) {
            this.orders = res.data;
            this.page = page;
            this.tot_pages = Math.ceil(res.tot / this.limit);
          }
        });
    },
  },
  created() {
    this.get(1);
    this.getOrdersStatus();
  },
};
</script>

<template>
  <!-- <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between mb-1">
            <div class="card-title">{{ $t("orders.filters.title") }}</div>
            <button
              class="btn btn-success"
              data-bs-toggle="modal"
              data-bs-target="#filterModal"
            >
              <i class="bx bx-plus"></i> {{ $t("orders.filters.title") }}
            </button>
          </div>
          <hr />
          <form
            @submit.prevent="filterOrders()"
            v-if="conditions[0].value || conditions[1].value"
          >
            <div v-if="conditions[0].value" class="row mb-2 filter-card">
              <div class="col-xm-12 col-sm-6 mb-1">
                <label class="control-label">{{
                  $t("orders.filters.status")
                }}</label>
                <select
                  required
                  v-model="orderFilter.status_id"
                  name=""
                  id=""
                  class="form-control form-select"
                >
                  <option value="0" selected>
                    {{ $i18n.locale == "ar" ? "الكل" : "all" }}
                  </option>
                  <option
                    :value="status.id"
                    v-for="status in ordersStatus"
                    :key="status"
                  >
                    {{
                      $i18n.locale == "ar" ? status.status_ar : status.status
                    }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="conditions[1].value" class="row mb-2 filter-card">
              <div class="col-xm-12 col-sm-6 mb-1">
                <label class="control-label">{{
                  $t("orders.filters.sort_by")
                }}</label>
                <select
                  required
                  v-model="orderFilter.sort_id"
                  name=""
                  id=""
                  class="form-control form-select"
                >
                  <option :value="item" v-for="item in sorts" :key="item">
                    {{ $i18n.locale == "ar" ? item.name : item.name_en }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="conditions[2].value" class="row mb-2 filter-card">
              <div class="col-xm-12 col-sm-6 mb-1">
                <label class="control-label">{{
                  $t("orders.filters.date")
                }}</label>
                <div class="mt-1">
                  <span style="margin-inline-end: 2px">{{
                    $t("orders.filters.from")
                  }}</span>
                  <input
                    required
                    v-model="orderFilter.from"
                    class="form-control"
                    type="date"
                    name=""
                    id=""
                  />
                  <span style="margin-inline-end: 2px">{{
                    $t("orders.filters.to")
                  }}</span>
                  <input
                    required
                    v-model="orderFilter.to"
                    class="form-control"
                    type="date"
                    name=""
                    id=""
                  />
                </div>
              </div>
            </div>
            <div class="mt-2">
              <button
                v-if="!loading"
                type="submit"
                class="btn btn-success"
                style="margin-inline-end: 8px"
              >
                {{ $t("popups.apply") }}
              </button>
              <button
                v-if="loading"
                disabled
                type="submit"
                class="btn btn-success"
                style="margin-inline-end: 8px"
              >
                <b-spinner small></b-spinner>
                <span class="sr-only">loading ...</span>
              </button>
              <button
                class="btn btn-secondary"
                @click="cancelFilters()"
                style="margin-inline-end: 8px"
              >
                {{ $t("popups.cancel") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> -->
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- Table data -->
          <div class="table-responsive">
            <table
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
              class="table table-centered table-nowrap table-striped table-hover align-middle"
            >
              <thead>
                <tr
                  class="text-light"
                  style="background-color: #2a3042 !important"
                >
                  <th>#</th>
                  <th>{{ $t("orders.id") }}</th>
                  <th>{{ $t("orders.order_date") }}</th>
                  <th>{{ $t("orders.status") }}</th>
                  <th>{{ $t("orders.total") }}</th>
                  <th>{{ $t("orders.customer.name") }}</th>
                  <th>{{ $t("orders.customer.img") }}</th>
                  <th>{{ $t("orders.customer.phone_email") }}</th>
                  <th>{{ $t("orders.customer.msg") }}</th>
                  <th>{{ $t("orders.operations") }}</th>
                </tr>
              </thead>
              <tbody v-if="!loading">
                <tr v-for="(app, index) in orders" :key="app">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <router-link :to="'/order-deatiles/' + app?.id">
                      # {{ app.id }}</router-link
                    >
                  </td>
                  <td>{{ app?.created?.split("T")[0] }}</td>
                  <td>
                    {{ $i18n.locale == "ar" ? app?.status_ar : app?.status }}
                  </td>
                  <td>{{ parseFloat(app?.order_price).toLocaleString() }}</td>
                  <td>
                    <router-link :to="'/order-deatiles/' + app?.id">
                      {{ `${app?.first_name} ${app?.last_name}` }}
                    </router-link>
                  </td>
                  <td>
                    <router-link :to="'/order-deatiles/' + app?.id">
                      <img
                        :src="
                          $store.state?.auth?.mediaUrl +
                          app?.image?.split('public')[1]
                        "
                        style="width: 64px; height: 64px"
                        alt=""
                    /></router-link>
                  </td>
                  <td>
                    <p class="mb-1">{{ app?.tel }}</p>
                    <p class="mb-0">{{ app?.email }}</p>
                  </td>
                  <td>
                    <button
                      class="btn btn-info mx-1"
                      role="button"
                      @click="viewOrderMsg(app)"
                      data-bs-toggle="modal"
                      data-bs-target="#msgModal"
                      data-toggle="tooltip"
                      data-placement="top"
                      :title="app?.id + ' ' + $t('products.descr')"
                    >
                      <i class="bx bxs-show"></i>
                    </button>
                  </td>
                  <td>
                    <button
                      v-if="!accepting"
                      :disabled="app?.is_accepted"
                      class="btn btn-success mx-1"
                      @click="acceptOrder(app)"
                      href="javascript: void(0);"
                      role="button"
                    >
                      {{ $t("orders.accept") }}
                    </button>
                    <button
                      v-if="accepting"
                      disabled
                      type="submit"
                      class="btn btn-success"
                      style="margin-inline-end: 8px"
                    >
                      <b-spinner small></b-spinner>
                      <span class="sr-only">loading ...</span>
                    </button>
                    <button
                      v-if="!rejecting"
                      :disabled="app?.is_accepted"
                      class="btn btn-danger"
                      href="javascript: void(0);"
                      role="button"
                      @click="rejectOrder(app)"
                    >
                      {{ $t("orders.reject") }}
                    </button>
                    <button
                      v-if="rejecting"
                      disabled
                      type="submit"
                      class="btn btn-success"
                      style="margin-inline-end: 8px"
                    >
                      <b-spinner small></b-spinner>
                      <span class="sr-only">loading ...</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Loader class="mt-4" v-if="loading" :loading="loading" />
          <!-- <ul class="pagination pagination-rounded justify-content-end mb-2">
              <li class="page-item disabled">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  aria-label="Previous"
                >
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li class="page-item active">
                <a class="page-link" href="javascript: void(0);">1</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript: void(0);">2</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript: void(0);">3</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript: void(0);">4</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript: void(0);">5</a>
              </li>
              <li class="page-item">
                <a
                  class="page-link"
                  href="javascript: void(0);"
                  aria-label="Next"
                >
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul> -->
          <!--   Apps  paginate     -->
          <ul class="pagination pagination-rounded justify-content-end mb-2">
            <li class="page-item" :class="{ disabled: page == 1 }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page - 1)"
                aria-label="Previous"
              >
                <i class="mdi mdi-chevron-left"></i>
              </a>
            </li>
            <li
              class="page-item"
              :class="{ active: p == page }"
              v-for="p in tot_pages"
              :key="p"
            >
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(p)"
                >{{ p }}</a
              >
            </li>

            <li class="page-item" :class="{ disabled: page == tot_pages }">
              <a
                class="page-link"
                href="javascript: void(0);"
                @click="get(page + 1)"
                aria-label="Next"
              >
                <i class="mdi mdi-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->

  <!-- start filters modal -->

  <div
    class="modal fade"
    id="filterModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div>
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("orders.filters.title") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="margin: 0"
            ></button>
          </div>

          <div class="modal-body" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <div class="row purchase-container">
              <div class="row d-flex align-items-center mb-2">
                <label>{{ $t("orders.filters.chose") }}</label>
                <div
                  class="mb-3"
                  v-for="condition in conditions"
                  :key="condition"
                >
                  <b-form-checkbox
                    :id="condition?.id"
                    v-model="condition.value"
                    name="checkbox-1"
                  >
                    {{
                      $i18n.locale == "ar" ? condition.name : condition.name_en
                    }}
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end filters modal -->

  <!-- start msg modal -->

  <div
    class="modal fade"
    id="msgModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div>
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t("orders.order") }} #{{ selectedOrder?.id }}
              {{ $t("orders.msg") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="margin: 0"
            ></button>
          </div>

          <div class="modal-body" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <div class="div">
              <p>{{ selectedOrder?.msg }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end msg modal -->
</template>

<style scoped>
td {
  padding: 1rem;
}
.filter-card {
  padding: 4px;
  background-color: rgb(237, 235, 235);
}
</style>
